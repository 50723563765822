import Vue from 'vue'
import VueRouter from 'vue-router'
// import WelcomeView from '../views/WelcomeView.vue'
import { getLocalLanguage,hardcodeLang } from '../i18n'
import store from '../store'
Vue.use(VueRouter)

export const CATALOGUE = "CATALOGUE"
export const CATEGORY = "CATEGORY"
export const PERFUME = "PERFUME"
export const RECOMMENDATION = "RECOMMENDATION"
const REDIRECT = 'REDIRECT'
export const WIZARD_V2 = "WIZARD_V2" 
import { i18n } from '../main'

export const routes = [
  {
    path: '/:catalogue/:locale',
    name:'LOCATE',
    component: () => import('../views/Locate.vue'),
    beforeEnter(to, from, next) {
      const locale = to.params.locale
      if (locale && !hardcodeLang.map(e => e.id).includes(locale)) return next(`/${to.params.catalogue}/${getLocalLanguage()}`)
      store.commit('setCatalogue',to.params.catalogue)
      return next()
    },
    children: [

     {
      path:'',
      name:WIZARD_V2,
      component:()=> import( /* webpackChunkName: "wizard" */ '../views/Wizard.vue'),
    },
    {
      path:'reco',
      name:RECOMMENDATION,
      component:()=> import( /* webpackChunkName: "recommendation" */ '../views/Recommendation.vue'),
    },
    {
      path: 'category/:category',
      name: CATEGORY,
      component: () => import(/* webpackChunkName: "category" */ '../views/CategoryView.vue'),
      meta: {
        isWizard: true,
        step: 2
      }
    },
    {
      path: 'category/:category/:perfume',
      name: PERFUME,
      component: () => import(/* webpackChunkName: "perfume" */ '../views/PerfumeView.vue'),
      meta: {
        isWizard: true,
        step: 3
      }
    },
    ]
  },
  {
    // Necesary because if we use opcional params like '/:catalogue/:locale?', then when we use $router.push({name:'GENDER'}) the locale is null
    path: '/:catalogue',
    name:'LOCATE_2',
    component: () => import('../views/Locate.vue'),
  },
  {
    path:'*',
    name:REDIRECT,
    redirect:() => {
      console.debug('redirect to ',`/ww/${getLocalLanguage()}`)
      return `/ww/${getLocalLanguage()}`
    }
  }
]
const router = new VueRouter({
  mode: process.env.VUE_APP_ELECTRON === 'true' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.params.locale){
    i18n.locale = to.params.locale
    store.commit('setLanguage',to.params.locale)
    document.documentElement.setAttribute("lang", to.params.locale);
  }
  next()
})

export default router
