/**
 * @enum {number}
 */
export const CTA_IDS = {
  ONLINE:1,
  AIRPARFUME:2,
  NO_AIRPARFUME:3
}

/**
 * @enum {string}
 */
export const WIZARD_TYPE = {
  QUIZ:'start',
  CATALOGUE:'TnS'
}