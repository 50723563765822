import axios from 'axios'
import { i18n } from '../main'
import qs from 'qs'
import { calculateFamilyDistance, calculateIngredientsScore, calculateReco } from './recoOffline'
const CancelToken = axios.CancelToken
const PACO_RABBANE_ID = process.env.VUE_APP_BACK_OFFICE_ID
const isElectron = process.env.VUE_APP_ELECTRON === 'true'
let cancels = {}
/**
 * Model of profile or sale point element
 * @typedef ProfileOrSalesPoint
 * @type {object}
 * @property {string} id 
 * @property {number} backofficeId 
 * @property {number[]} catalog
 * @property {string[]} langs 
 * @property {string} name 
 * @property {{cta:string, lang:string, text:string }[]} texts
 */
function cancelRequest(id) {
  if (cancels[id]) cancels[id]()
  cancels[id] = null
}
let instance = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

let cancelableInstance = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
function interceptor(config){
  config.params = config.params || {}
  if(config.addLang!==false)
    config.params.lang= i18n.locale
  if(config.addHost!==false)
    config.params.hostId = process.env.VUE_APP_HOST_ID
  return config
}
var promiseApiMap = null
async function getApiMap(){
  if(promiseApiMap) return promiseApiMap
  promiseApiMap = axios.get(process.env.BASE_URL + 'api-map.json')
  return promiseApiMap
}
instance.interceptors.request.use(interceptor)
const instanceget = instance.get
if(isElectron)
  instance.get = async function (path,config, ...other) {
    var params = config?.params || {}
    config = interceptor(config || {})
    var apiJson = {}
    var qstring = qs.stringify(params,{encode:false})
    var newPath = path
    try {
      const response = await getApiMap()
      newPath = path + (path.includes('?') ? '&' : '?') + qstring
      apiJson = response.data
    } catch (e) {
      console.warn('api-map.json not found', e)
    } finally {
      if (apiJson[newPath]) console.debug('Fetch local', newPath, apiJson[newPath])
      else console.debug('Fetch remote', newPath)
      newPath = apiJson[newPath] || newPath
    }
    return instanceget(newPath,config, ...other)
  }
const cancelabelCache = {}
cancelableInstance.interceptors.request.use(config => {
  /// CANCELABLE
  config.params = Object.assign(config.params || {}, {
    /** Maybe its not necesary */
    lang: i18n.locale,
    hostId: process.env.VUE_APP_HOST_ID
  })
  let reqId = config.url.split('?')[0]
  cancelRequest(reqId)
  config.cancelToken = new CancelToken(c => (cancels[reqId] = c))
  return config
})
/**
 * send always Unisex
 * @return {Promise<{id:string,name:string,color:string,imageUrl:string,primary:boolean,intensity:number}[]>}
 */
export function getAllFamilies(lang, gender, configId) {
  if(!gender) gender = 'U'
  if (gender !== 'U') {
    gender = `${gender},U`
  }
  const path = `/families/`
  return instance
    .get(process.env.VUE_APP_APIURL + path, { params: { lang, gender, configId} })
    .then(res => res.data)
}
/**
 * @param {Number[]} families REQUIRED. if not all ingredients return []
 * @return {Promise<IngredientType[]>}
 */
export async function getAllIngredients(lang, gender, configId, families) {
  const path = `/ingredients/`
  // its necesary for doing fetch
  if(!families || families.length===0) return []
  const familiesString = families.join(',')
  if(!gender) gender = 'U'
  if (gender !== 'U') {
    gender = `${gender},U`
  }
  if(isElectron) return getIngredientsElectron(lang, gender, configId, families)
  return instance
    .get(process.env.VUE_APP_APIURL + path, { params: { lang, gender, configId, family: familiesString } })
    .then(response => response.data)
}
async function getIngredientsElectron(lang,gender,configId,families){
  const ingredients = await instance.get(process.env.VUE_APP_APIURL + `/ingredients/`,{
    params:{lang, gender, configId}
  }).then(res => res.data)
  return ingredients.filter(ingredient => families.some(family => ingredient.family.id===family))
}

/**
 * @return {Promise<any[]>}
 */
export function getConcepts(lang, gender) {
  if(!gender) gender = 'U'
  let path = `/paco/concepts/`
  return instance
    .get(process.env.VUE_APP_APIURL + path, { params: { lang, gender } })
    .then(response => response.data)
}

/**
 * 
 * @param {{ingredientId:number,proportion:number}[]} ingredients Proportion must be between 0 to 10
 * @param {"F" | "M" | "U" | null} gender 
 * @param {number} intensity 
 * @param {number} subbrand
 * @param {number[]} families
 * 
 */
export function getSimilarByIngredients(lang, ingredients, concepts = [], gender, intensity, subbrand, configId,families=[]) {
  // if(isElectron) return recoElectron(lang,ingredients,concepts,gender,intensity,configId,families)
  if(!navigator.onLine && isElectron) return recoElectron(lang,ingredients,concepts,gender,intensity,configId,families)
  let qs = `light=true&`
  if (!gender) gender = 'U'
  if (gender === 'U') qs += `included.gender=${gender}&` //send always Unisex
  else if (gender) qs += `included.gender=${gender},U&` //send always Unisex

  // if (subbrand) qs += `subbrand_ids=${subbrand}&`
  if (intensity) qs += `intensity=${intensity*10}&` // entre 0 i 10
  if (families && families.length>0 ) qs +=`familiesAgg=${families.join(',')}&`
  if (ingredients && ingredients.length > 0) {
    qs += `ingredientsAgg=${ingredients.map(i => i.ingredientId).join(',')}&`
  }
  qs += `configId=${configId}&`
  qs += "limit=5&"
  if (concepts && concepts.length > 0) qs += `conceptsPaco=${concepts.join(',')}&`
  let path = process.env.VUE_APP_APIURL + `/v7/reco?${qs}`
  if(isElectron) path = process.env.VUE_APP_REMOTE_HOST + path
  if(isElectron)
    setTimeout(()=>{
      let reqId = path.split('?')[0]
      cancelRequest(reqId)
    },5000)
  if(cancelabelCache[path]) return Promise.resolve(cancelabelCache[path])  
  return cancelableInstance
    .get( path, { params: { lang } })
    .then(response => {
      return response.data.data.map(info => info.id)
    })
    .then(ids => ids.length===0 ? [] : getPerfumesByIds(ids,configId))
    .then(res => {
      cancelabelCache[path] = res
      return res
    })
    .catch(err => {
      console.log(err)
      if(axios.isCancel(err)){
        console.log('reco cancel, try recoElectron')
        if(isElectron)
          return recoElectron(lang,ingredients,concepts,gender,intensity,configId,families)
        return undefined
      }
      else throw err
    })
}
async function recoElectron(lang,ingredients,concepts,gender,intensity,configId,families){
  // intensity = intensity/10
  const conceptsFetched = await getConcepts(lang,gender)
  gender = gender + ',U'
  const perfumes =  await getAllPerfumes(configId)
  const conceptsMaped = conceptsFetched.filter(c => concepts.includes(c.id))
  const ings = ingredients.concat(conceptsMaped
    .map(c => c.ingredients.sort((a,b)=>b.proportion-a.proportion).slice(0,10))
    .flat())
  var max = 0
  var maxFamily = 0
  var selectedIngs = ings.reduce((obj,{ingredientId,proportion})=>({...obj,[ingredientId]:proportion}),{})
  perfumes.forEach(perfume => {
    var perfIngredients = perfume.ingredientProportions.reduce((obj,{ingredient,proportion})=>({...obj,[ingredient.id]:proportion}),{})
    perfume._ingredients = perfIngredients
    var ingrDist = calculateIngredientsScore(perfIngredients,selectedIngs)
    max = Math.max(ingrDist,max)
    var famDist = calculateFamilyDistance(perfume,families)
    maxFamily = Math.max(maxFamily,famDist)
  })
  perfumes.forEach(perfume => {
    perfume.recoValue = calculateReco({
      ingredients:perfume._ingredients,
      intensity:perfume.intensity,
      perfume
    },{
      ingredients:selectedIngs,
      families,
      intensity
    },max,maxFamily)
  })
  return perfumes
    .filter(perfume => gender.split(',').includes(perfume.gender))
    .sort((a,b)=>a.recoValue-b.recoValue)
    .map(e => ({...e,productUrl:'https://remove-me-:)'}))
    .slice(0,10)
}
/**
 * Get perfume by id
 * @param {number} perfumeId 
 * @param {number} configId 
 * @param {string} lang 
 */
export async function getPerfumeById(perfumeId, configId, lang) {
  if(isElectron) {
    const map = await getMapPerfumes(configId,lang)
    return map.get(perfumeId)
  }
  const path = `/perfumes/${perfumeId}`
  return instance
    .get(process.env.VUE_APP_APIURL + path, { params: { configId, lang } })
    .then(response => response.data)
}

/**
 * @param {number} id
 * @return {Promise<ProfileOrSalesPoint[]>} 
 */
export function getProfilerById(id) {
  return instance
    .get(process.env.VUE_APP_APIURL + `/backoffice/${PACO_RABBANE_ID}/profiler/${id}`,{addLang:false, addHost:false})
    .then(response => response.data)
}

/**
 * @param {number} id
 * @return {Promise<ProfileOrSalesPoint[]>} 
 */
export function getSalepointById(id) {
  return instance
    .get(process.env.VUE_APP_APIURL + `/backoffice/${PACO_RABBANE_ID}/salepoint/${id}`,{addLang:false, addHost:false})
    .then(response => response.data)
}
/**
 * 
 * @param {number} id 
 */
export function getGenders(id) {
  return instance
    .get(process.env.VUE_APP_APIURL + `/paco/genders`,{params:{configId:id}, addLang:false}).then(res => res.data)
}
/**
 * @param {number}configId
 * @param {string} gender
 */
export function getPerfumeCategories(configId,gender) {
  if(!gender) gender = 'U'
  return instance.get(process.env.VUE_APP_APIURL + `/paco/catalog`,{
    params:{
      gender,
      hideEmptyCategories:true,
      configId
    }
  }).then(res => res.data)
}
/**
 * 
 * @param {number}configId
 * @param {string} gender
 * @param {string} id
 */
export function getPerfumeCategoryById(configId,gender, id) {
  if(!gender) gender = 'U'
  return instance.get(process.env.VUE_APP_APIURL + `/paco/catalog/${id}`,{
    params:{
      gender,
      hideEmptyCategories:true,
    },
    addHost:false
  }).then(res => res.data)
}
/**
 * 
 * @param {string} gender
 * @param {string} id
 */
 export function getCategorizedPerfumes(gender, categoryId, configId) {
  return instance.get(process.env.VUE_APP_APIURL + `/paco/catalog/${categoryId}/perfumes`,{
    params:{
      gender,
      configId
    },
    addLang: false
  }).then(res => res.data)
}
/**
 * 
 * @param {Array<string>} ids
 */
 export function getPerfumesByIds(ids, catalogue) {
  if(isElectron) return getPerfumeByIdElectron(ids,catalogue)
  if(!ids || ids.length===0) return []
  return instance.get(process.env.VUE_APP_APIURL + `/perfumes/by_ids?sortByEntryOrder=true&configId=${catalogue}&allPresent=false&perfumeIds=${ids.join(',')}`).then(res => res.data)
}
async function getPerfumeByIdElectron(ids,configId){
  if(!ids) return []
  const map = await getMapPerfumes(configId)
  return ids.map(id => map.get(id)).filter(e => e)
}
/**
 * 
 * @param {string} productFeed 
 * @return {Promise<ProfileOrSalesPoint>}
 */
export function getProfilerByProductFeed(productFeed) {
  return instance.get(process.env.VUE_APP_APIURL + `/backoffice/${PACO_RABBANE_ID}/profiler/productFeedId/${productFeed}`).then(res => res.data)
}

/**
 * 
 * @returns {Promise<{id:number,genders:{id:string,color:string}[]}[]>}
 */
export function getSteps(){
  return instance.get(process.env.VUE_APP_APIURL +`/paco/steps`).then(data => data.data)
}

export function getIntensities(){
  return instance.get(process.env.VUE_APP_APIURL +`/paco/intensities`).then(data => data.data)
}
export function getCatalog(gender){
  return instance.get(process.env.VUE_APP_APIURL +`/paco/catalog`,{params:{gender}}).then(data => data.data)
}
/** @type {{[string]:Map}} */
var perfumesMap = {}
async function getAllPerfumes(configId,lang){
  return instance.get(process.env.VUE_APP_APIURL +`/perfumes/`,{params:{configId,lang}}).then(data => data.data)
}
async function getMapPerfumes(configId,lang){
  if(!configId) console.error('CONFIG ID NOT FOUND')
  if(perfumesMap[lang]) return perfumesMap[lang]
  const perfumes = await getAllPerfumes(configId)
  perfumesMap[lang] = new Map()
  perfumes.forEach(p => perfumesMap[lang].set(p.id,p))
  return perfumesMap[lang]
}
export default instance
