<template>
  <div
    v-if="backoffice"
    id="app"
    :class="['app', deviceClass, routeClass, genderClass, machineClass]"
  >
    <router-view />
    <restart-pop-up />
  </div>
</template>
<script>
import device from "@/utils/device";
import queryStringMixin from "./mixins/queryStringMixin";
import getSalepointMixin from "./mixins/getSalepointMixin";
import { mapActions, mapGetters } from "vuex";
import RestartPopUp from "./components/RestartPopUp.vue";
export default {
  components: { RestartPopUp },

  name: "App",
  created() {
    this.startTimer();
    const onInteract = () => {
      this.startTimer();
    };
    window.addEventListener("touchend", onInteract);
    window.addEventListener("mouseup", onInteract);
  },
  mounted(){
    if (process.env.VUE_APP_ELECTRON === 'true' && window.electron) {
      console.debug('check if is updated')
      // eslint-disable-next-line
      electron?.isUpdated().then(bool => {
        console.log('need reoload = ', bool)
      })  
    }
  },
  data() {
    return {
      transitionName: "down-to-up",
      step: 0,
      route: "welcome",
    };
  },
  computed: {
    ...mapGetters(["lang", "isTimerFinished"]),
    deviceClass() {
      return device;
    },
    routeClass() {
      return this.route;
    },
    containerClass() {
      return this.isProfiler ? "app-container" : "app-container--salespoint";
    },
    genderClass() {
      return this.isMan ? "man" : this.isWoman ? "woman" : "unisex";
    },
    machineClass() {
      return this.isProfiler ? "profiler" : "machine";
    },
  },
  methods: {
    ...mapActions(["startTimer"]),
  },
  mixins: [queryStringMixin, getSalepointMixin],
  watch: {
    backoffice() {
      if (this.backoffice)
        this.$i18n.setLocaleMessage(
          this.lang,
          this.backoffice.texts[this.lang]
        );
    },
  },
};
</script>
<style lang="stylus">
$inner-height = calc(var(--vh, 1vh) * 100)

html
  box-sizing: border-box
  // height: 100%
  // height: 100vh
  height: $inner-height // este es el "bueno" :D (no es el "max height del borwer" sino el "inner height")
  overflow: hidden

*
  text-rendering: optimizeLegibility
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased

*,
*:before,
*:after
  box-sizing: inherit

body
  color: $base-font-color
  font-family: $base-font-family
  font-weight: $fw-regular
  height: $inner-height
  margin: 0
  overflow: hidden
  position: relative

ul,
li
  list-style-type: none
  margin: 0
  padding: 0

// NEEDED TO MEASURE VW/VH for RespnosiveCss.js
#measure
  height: $inner-height
  overflow: hidden
  pointer-events: none
  position: absolute
  visibility: hidden
  width: 100vw

.app
  height: 100%

  &.welcome
    > .back-btn
      display: none

  &.recommendation
    > .back-btn,
    .lang-selector
      display: none

.app-container
  // height: 100vh
  height: "calc(var(--vh, 1vh) * 100 - %s)" % $steps-height
  // overflow-y: auto
  overflow: hidden
  position: inherit

  &--salespoint
    height: 100vh

.steps
  height: $steps-height

.up-to-down-enter-active,
.up-to-down-leave-active
  transition: 0.2s ease-in

.up-to-down-enter
  opacity: 0
  transform: translate(0, -400px)

.up-to-down-leave-to
  opacity: 0
  transform: translate(0, 400px)

.down-to-up-enter-active,
.down-to-up-leave-active
  transition: 0.2s ease-in

.down-to-up-enter
  opacity: 0
  transform: translate(0, 400px)

.down-to-up-leave-to
  opacity: 0
  transform: translate(0, -400px)

#nav
  padding: 30px

#nav
  a
    color: #2c3e50
    font-weight: bold

    &.router-link-exact-active
      color: #42b983
</style>
