import Vue from 'vue'
import Vuex from 'vuex'
import catalogue from './catalogue'
import conceptStore from './conceptStore'
import familyStore from './familyStore'
import genderStore from './genderStore'
import i18n from './i18n'
//import dataCache from './dataCache'
import ingredientStore from './ingredientStore'
import perfumeCategoryStore from './perfumeCategoryStore'
import categorizedPerfumes from './categorizedPerfumes'
import allCategoriesPerfumes from './allCategoreisPerfumesStore'
import profilerStore from './profilerStore'
import recommendationStore from './recommendationStore'
import salepointStore from './salepointStore'
import steps from './stepsStore'
import intensities from './intensitiesStore'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    i18n: i18n,
    ingredients: ingredientStore,
    families: familyStore,
    concepts: conceptStore,
    reco: recommendationStore,
    genders: genderStore,
    profiler: profilerStore,
    salepoint: salepointStore,
    catalogue,
    categorizedPerfumes,
    perfumeCategory: perfumeCategoryStore,
    allCategoriesPerfumes,
    steps,
    intensities
  },
  getters: {
    lang: (state) => state.i18n.lang,
  },
  state: {
  },
  mutations: {
  },
  actions: {
  }
})
