import Vue from 'vue'

/**
 * @typedef SaveReqType
 * @property {T} result
 * @property {boolean} updating
 * @property {Error | null} error
 * @property {boolean} invalidate
 * @template T
 */
/**
 * @typedef { {[lang:string]:{[id: string]:SaveReqType<T>}} } ObjectsStoreType
 * @template T
 */
/**
 * @typedef {{[path:string]:SaveReqType<number[]>}} RequestStoreType
 */
/**
 * @typedef {{objects:ObjectsStoreType<T>,requests:RequestStoreType}} StateType
 * @template T
 */
/**
 * @typedef CachedListsType
 * @property {(obj:T)=>string} getId
 * @property {(...args:ArgsGet)=>Promise<T[]>} get
 * @property {(id:string|number,...args:ArgsGetById)=>Promise<T>} getById
 * @property {(...args:ArgsGet)=>string} getLang
 * @property {(...args:ArgsGetById)=>string} getLangById
 * @property {()=>string} lang
 * @template T, ArgsGet, ArgsGetById
 */
/**
 * @param {import('vuex').Module & {cachedLists:{[name:string]:CachedListsType<T,ArgsGet,ArgsGetById>}}} store 
 * @template T, ArgsGet, ArgsGetById
 * @version 1.1 
 */
export default function (store) {
    let cachedKeys = Object.keys(store.cachedLists)
    const name = cachedKeys[0]
    const cachedList = store.cachedLists[name]
    let langGetter = cachedList.lang ?? cachedList.getLang
    let langGetterById = cachedList.lang ?? cachedList.getLangById
    let getId = cachedList.getId ? cachedList.getId : (d) => d.id
    /**
     * 
     * @param  {ArgsGet | ArgsGetById} args 
     */
    let createPath = (...args) => {
        return args.filter(e => e!==undefined).map(arg => JSON.stringify(arg)).join('-')}
    let api = { get: cachedList.get, getById: cachedList.getById }

    const SET_LANGUAGE = 'SET_LANGUAGE_' + name
    const SET_OBJECTS = 'SET_OBJECT_' + name
    const SET_REQUEST = 'SET_REQUEST_' + name
    const FAILURE_REQUEST = 'FAILURE_REQUEST_' + name
    const SET_UPDATING = 'SET_UPDATING_' + name
    const SET_INVALIDATE = 'SET_INVALIDATE_' + name
    const SET_INVALIDATE_ALL = 'SET_INVALIDATE_ALL_' + name
    const SET_INVALIDATE_BY_ID = 'SET_INVALIDATE_BY_ID_' + name
    const SET_UPDATING_BY_ID = 'SET_UPDATING_BY_ID_' + name
    const FAILURE_REQUEST_BY_ID = 'FAILURE_REQUEST_BY_ID_' + name
    const SET_REQUEST_BY_ID = 'SET_REQUEST_BY_ID_' + name
    // const apiLangSelectorInteral = {
    //     get: () => DEFAUT_LANG,
    //     getById: () => DEFAUT_LANG,
    //     ...apiLangSelector
    // }
    return {
        namespaced: true,
        state: {
            ...store.state,
            objects: {},
            requests: {}
        },
        getters: {
            ...store.getters,
            /**
             * @param {StateType<T>} state
             */
            get(state, getters, rootState) {
                return (...args) => {

                    let lang = langGetter(rootState, ...args)
                    const path = createPath(...args, lang)
                    if (state.requests
                        && state.objects //TODO: chapu para que "escuche" cuando se añade un lenguaje
                        && state.requests[path]
                        && state.objects[lang]
                        && state.requests[path].result
                        && state.requests[path].result.every(id => state.objects[lang][id] !== undefined)) {
                        return state.requests[path].result.map(id => state.objects[lang][id].result)
                    } else {
                        return []
                    }
                }
            },
            /**
             * @param {StateType<T>} state
             */
            getById(state, getters, rootState) {
                return (id, ...args) => {
                    let lang = langGetterById(rootState, ...args)
                    if (state.objects && state.objects[lang] && state.objects[lang][id])
                        return state.objects[lang][id].result
                    return null
                }
            },
            /**
             * @param {StateType<T>} state
             */
            updating: (state, getters, rootState) => (...args) => {
                let lang = langGetter(rootState, ...args)
                const path = createPath(...args, lang)
                if (state.requests[path]) {
                    return state.requests[path].updating
                } else {
                    return null
                }
            },
            /**
             * @param {StateType<T>} state
             */

            isInvalidate: (state, getters, rootState) => (...args) => {
                let lang = langGetter(rootState, ...args)
                const path = createPath(...args, lang)
                if (state && state.requests && state.requests[path])
                    return state.requests[path].invalidate
                return null
            },
            /**
             * @param {StateType<T>} state
             */

            isInvalidateById(state, getters, rootState) {
                return (id, ...args) => {
                    const lang = langGetterById(rootState, ...args)
                    if (state.objects[lang] && state.objects[lang][id])
                        return state.objects[lang][id].invalidate
                    return null
                }
            },
            /**
             * @param {StateType<T>} state
             */
            updatingById(state, getters, rootState) {
                return (id, ...args) => {
                    const lang = langGetterById(rootState, ...args)
                    if (state.objects && state.objects[lang] && state.objects[lang][id]) {
                        return state.objects[lang][id].updating
                    } else {
                        return null
                    }
                }
            },
            /**
             * @param {StateType<T>} state
             */
            error: (state, getters, rootState) => (...args) => {
                let lang = langGetter(rootState, ...args)
                const path = createPath(...args, lang)
                if (state.requests[path]) {
                    return state.requests[path].error
                } else {
                    return null
                }
            },
            /**
             * @param {StateType<T>} state
             */
            errorById(state, getters, rootState) {
                return (id, ...args) => {
                    const lang = langGetterById(rootState, ...args)
                    if (state.objects && state.objects[lang] && state.objects[lang][id]) {
                        return state.objects[lang][id].error
                    } else {
                        return null
                    }
                }
            },

            /**
             * @param {StateType<T>} state
             */
            getInfo(state, getters, rootState) {
                return (...args) => {
                    const lang = langGetter(rootState, ...args)
                    const path = createPath(...args, lang)
                    if (state.requests
                        && state.objects
                        && state.requests[path]
                        && state.objects[lang]
                        && state.requests[path].result.every(id => state.objects[lang][id].result !== undefined))
                        return {
                            ...state.requests[path],
                            result: state.requests[path].result ? state.requests[path].result.map(id => state.objects[lang][id].result) : []
                        }
                    else {
                        return {
                            result: [],
                            updating: false,
                            invalidate: false,
                            error: null,
                        }
                    }
                }
            },
            /**
             * @param {StateType<T>} state
             */
            getInfoById(state, getters, rootState) {
                return (id, ...args) => {
                    const lang = langGetterById(rootState, ...args)
                    if (state.objects[lang] && state.objects[lang][id])
                        return state.objects[lang][id]
                    else
                        return {
                            result: null,
                            updating: false,
                            invalidate: false,
                            error: null,
                        }
                }
            }
        },
        actions: {
            ...store.actions,
            /**
             * 
             * @param  {ArgsGet} args 
             */
            ['get']({ dispatch }, args) {
                args = args || []
                return dispatch('fetch', ...args)
            },
            /**
             * 
             * @param  {ArgsGetById} args 
             */
            ['getById']({ dispatch }, id, args) {
                args = args || []
                return dispatch('fetchById', id, ...args)
            },
            fetch({ dispatch, getters }, args) {
                args = args || []
                const updating = getters.updating(...args)
                const invalidate = getters.isInvalidate(...args)
                const data = getters.get(...args)
                const error = getters.error(...args)
                if (updating) return data
                else if ((!data && !error) || invalidate !== false) return dispatch('update', args)
                else return data
            },

            fetchById({ dispatch, getters }, [id, ...args]) {

                const updating = getters.updatingById(id, ...args)
                const invalidate = getters.isInvalidateById(id, ...args)
                const data = getters.getById(id, ...args)
                const error = getters.errorById(id, ...args)
                if (updating) return data
                else if ((!data && !error) || invalidate !== false) return dispatch('updateById', [id, ...args])
                else return data
            },
            update({ commit, dispatch, rootState }, args ) {
                args = args || []
                const lang = langGetter(rootState, ...args)
                const path = createPath(...args, lang)
                // console.log('UPDATE: ', name, path)

                commit(SET_UPDATING, { path })
                return api.get(rootState, ...args).then(objects => {
                    dispatch('save', { lang, objects, path })
                    return objects
                }).catch(error => commit(FAILURE_REQUEST, { error, path }))
            },

            updateById({ commit, dispatch, rootState }, [id, ...args]) {
                // console.log('Update by id', id);

                const lang = langGetterById(rootState, ...args)
                commit(SET_UPDATING_BY_ID, { id, lang })
                return api.getById(rootState, id, ...args).then(object => {
                    dispatch('saveById', { lang, id, object })
                }).catch(error => commit(FAILURE_REQUEST_BY_ID, { error, id, lang }))
            },
            save({ commit, state }, { lang, path, objects }) {
                if (!state.objects[lang]) commit(SET_LANGUAGE, { lang })
                let noCache = objects.reduce((obj, data) => {
                    let id = getId(data)
                    // noCache if (!state.objects[lang] || (!state.objects[lang][id] && !state.objects[lang][id].updating ))
                    obj[id] = data
                    return obj
                }, {})
                if (Object.keys(noCache).length > 0)
                    commit(SET_OBJECTS, { lang, objects: noCache })
                commit(SET_REQUEST, { objects: Object.values(objects), path })
            },
            saveById({ commit }, { lang, id, object }) {
                let objects = {
                    [id]: object
                }
                commit(SET_OBJECTS, { lang, objects })
            },
            invalidate({ commit, rootState }, args) {
                commit(SET_INVALIDATE, { path: createPath(...args, langGetter(rootState, ...args)) })
            },
            invalidateById({ commit, rootState }, [id, ...args]) {
                commit(SET_INVALIDATE_BY_ID, { lang: langGetterById(rootState, ...args), id })
            },
            invalidateAll({ commit }) {
                commit(SET_INVALIDATE_ALL)
            }

        },
        mutations: {
            ...store.mutations,
            [SET_LANGUAGE](state, { lang }) {

                let obs = {
                    ...state.objects,
                    [lang]: {}
                }
                Vue.set(state.objects, lang, obs)
            },
            /**
             * @param {StateType<T>} state 
             */
            [SET_OBJECTS](state, { lang, objects }) {
                // console.log('set Objects', name, objects)
                var obs
                if (state.objects)
                    obs = state.objects[lang] ?? {}
                obs = {
                    ...obs,
                    ...Object.keys(objects).reduce((obj, key) => ({
                        ...obj,
                        [key]: {
                            result: objects[key],
                            updating: false,
                            invalidate: false,
                            error: null,
                        }
                    }), {})
                }
                Vue.set(state.objects, lang, obs)
            },
            /**
             * @param {StateType<T>} state 
             */
            [SET_REQUEST](state, { path, objects }) {
                const req = {
                    result: objects.map(e => getId(e)),
                    updating: false,
                    invalidate: false,
                    error: null,
                }
                Vue.set(state.requests, path, req)
                // state = {...state}
            },
            /**
             * @param {StateType<T>} state 
             */
            [SET_REQUEST_BY_ID](state, { id, lang, object }) {
                const obj = {
                    result: object,
                    updating: false,
                    invalidate: false,
                    error: null,
                }
                Vue.set(state.objects[lang], id, obj)
                // state = {...state}
            },
            /**
             * @param {StateType<T>} state 
             */
            [FAILURE_REQUEST](state, { path, error }) {
                // console.log(FAILURE_REQUEST, error)
                const lastReq = state.requests[path] ?? {}
                const req = {
                    ...lastReq,
                    result: null,
                    updating: false,
                    invalidate: false,
                    error: error,
                }
                Vue.set(state.requests, path, req)
            },
            /**
             * @param {StateType<T>} state 
             */
            [FAILURE_REQUEST_BY_ID](state, { id, lang, error }) {
                // console.log(FAILURE_REQUEST_BY_ID, error)
                if (!state.objects[lang]) {
                    const obj = {
                        result: null,
                        updating: false,
                        invalidate: false,
                        error: error,
                    }
                    Vue.set(state.objects, lang, { [id]: obj })
                }
                else {
                    const lastObj = state.objects[lang] ? state.objects[lang][id] ?? {} : {}
                    const obj = {
                        ...lastObj,
                        result: null,
                        updating: false,
                        invalidate: false,
                        error: error,
                    }
                    Vue.set(state.objects[lang], id, obj)
                }


            },
            /**
             * @param {StateType<T>} state 
             */
            [SET_UPDATING](state, { path }) {
                if (state.requests[path])
                    state.requests[path].updating = true
                else
                    state.requests = {
                        ...state.requests,
                        [path]: {
                            result: null, //TODO
                            error: null,
                            updating: true,
                            invalidate: false
                        }
                    }
            },
            /**
             * @param {StateType<T>} state 
             */
            [SET_UPDATING_BY_ID](state, { lang, id }) {
                if (state.objects[lang] && state.objects[lang][id]) {
                    state.objects[lang][id].updating = true
                }
                else if (!state.objects[lang]) {
                    state.objects = {
                        [lang]: {
                            [id]: {
                                result: null, //TODO
                                error: null,
                                updating: true,
                                invalidate: false
                            }
                        }
                    }
                } else {
                    const obj = {
                        result: null,
                        error: null,
                        updating: true,
                        invalidate: false
                    }
                    Vue.set(state.objects[lang], id, obj)
                }


            },
            /**
             * @param {StateType<T>} state 
             */
            [SET_INVALIDATE_BY_ID](state, { lang, id }) {
                if (state.objects[lang] && state.objects[lang][id])
                    state.objects[lang][id].invalidate = true
                else
                    state.objects[lang][id] = {
                        result: null,
                        error: null,
                        updating: false,
                        invalidate: true
                    }
            },
            /**
             * @param {StateType<T>} state 
             */
            [SET_INVALIDATE](state, { path }) {
                if (state.requests[path])
                    state.requests[path] = {
                        ...state.requests[path],
                        invalidate: true
                    }
                else
                    state.requests[path] = {
                        result: null,
                        error: null,
                        updating: false,
                        invalidate: true
                    }
            },
            /**
             * @param {StateType<T>} state 
             */
            [SET_INVALIDATE_ALL](state) {
                Object.keys(state.requests).forEach(path => {
                    state.requests[path].invalidate = true
                })
                Object.keys(state.objects).forEach(lang => {
                    Object.keys(state.objects[lang]).forEach(id => {
                        state.objects[lang][id].invalidate = true
                    })
                })
            }
        }
    }

}