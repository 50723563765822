/**
 * 
 * @param {{[id:number]:number}} perfumeIngr perfume
 * @param {*} ingsProps2 
 */
export function calculateIngredientsScore(dictIngrProp1, dictIngrProp2){
  var sum = 0
  Object.entries(dictIngrProp1).forEach(([idIng1,proportion1])=>{
      if(dictIngrProp2[idIng1])
        sum+=dictIngrProp2[idIng1]*proportion1
  })
  return sum
}

export function calculateIntensityDistance(intensity1,intensity2){
  return Math.abs(intensity1-intensity2)
}
const w1 = 1
// const w2 = 1
/**
 * 
 * @param {any} perfumeFamilies 
 * @param {number[]} families
 * @returns 
 */
export function calculateFamilyDistance(perfume, families){
  return w1*multiFamilyDistance(perfume,families) //+ w2*multiFamilyDistance(perfume,families,'secondaryFamily')
}
const ING_WEIGHT = .22
const FAM_WEIGHT = .66
const INT_WEIGHT = .12


export function calculateReco({ingredients,intensity,perfume},{ingredients:ing2,families:fam2,intensity:int2},maxDistance,maxFamily){
  const ing = calculateIngredientsScore(ingredients,ing2)/maxDistance
  const fam = 1 - calculateFamilyDistance(perfume,fam2)/maxFamily
  const int = 1 - calculateIntensityDistance(intensity,int2)
  return ING_WEIGHT*ing + INT_WEIGHT*int + FAM_WEIGHT*fam
}
const PRIMARY_DISTANCE = {1:0, 5:1, 6:2, 10:3, 8:4, 9:5, 12:6}
// const SECONDARY_DISTANCE = {16:0, 1:2, 2:3, 4:4, 5:5, 3:6, 7:7, 6:8, 11:9, 10:10, 8:11, 14:12,15:13, 9:14, 13:15, 12:16}

const multiFamilyDistance = (p, fArray, field="family")=>{
  const DISTANCE = PRIMARY_DISTANCE//= field==='family' ? PRIMARY_DISTANCE : SECONDARY_DISTANCE
  fArray = fArray.filter(f=>DISTANCE[f]!=null)
  fArray.sort((a,b)=>DISTANCE[a]-DISTANCE[b])
  let i = 0
  let entries = Object.entries(DISTANCE).sort(([, v1],[, v2])=>{
      return v1-v2
  })
  const dict = entries.reduce((acc, [k,v])=>{
      let diff = Math.abs(v-DISTANCE[fArray[i]])
      if (fArray.length>i && Math.abs(v-DISTANCE[fArray[i+1]])<diff) {
          diff = Math.abs(v-DISTANCE[fArray[i+1]])
          i += 1
      }
      acc[k] = diff
      return acc
  },{})
  return dict[p[field]['id']]
}