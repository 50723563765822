
export default {
  state: {
    lang: localStorage.getItem("lang") || 'en'
  },
  mutations: {
    setLanguage(state,lang){
      state.lang=lang
    }
  },
  actions: {
    setLanguage(context,lang){
      console.log('set Lang')
      localStorage.setItem("lang",lang)
      //context.dispatch('clearCache')
      context.commit('setLanguage',lang)
    }
  }
}