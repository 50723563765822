// import i18nStoreRequest from "./lib/i18nStoreRequest";
import cachedLists from './lib/cachedLists'
import * as api from '../services/Api'
// export default i18nStoreRequest('PROFILER', {
//   get: () => api.getProfilers(),
//   getById: (id) => api.getProfilerById(id)
// })

export default cachedLists({
  state: {
    whatever: null
  },
  mutations: {
    //whatever mutations
  },
  actions: {
    //whatever actions
  },
  getters: {
    getPerfumeById: state =>
      (id, lang) => state.objects[lang][id].result
  },
  cachedLists: {
    categorizedPerfumes: {
      get: (state, categoryId, gender, configId) => api.getCategorizedPerfumes(gender, categoryId, configId),
      lang: (state) => state.i18n.lang
    }
  },
})