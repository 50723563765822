<template>
  <transition name="fade">
    <div v-if="open" class="smelling-overlay">
      <div class="background"></div>
      <div class="popup">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props:['open'],
};
</script>

<style scoped lang="stylus">
.smelling-overlay
  height: calc(var(--vh, 1vh) * 100)
  left: 0
  position: fixed
  top: 0
  width: 100vw
  z-index: 999

  &.fade-enter-active,
  &.fade-leave-active
    transition: opacity 0.5s

  &.fade-enter,
  &.fade-leave-to
    opacity: 0

.background
  background-color: #fff
  height: 100%
  opacity: 0.9
  position: absolute
  width: 100%

.popup
  display: flex
  flex-direction: column
  height: 100%
  left: 0
  padding: vw(40px)
  position: absolute
  top: 0
  width: 100%
</style>
