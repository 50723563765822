export default {
  screen_title: 'step',
  question_gender: 'I am looking for ...',
  gender_woman: 'woman fragrance',
  gender_man: 'man fragrance',
  gender_pacollection: 'pacollection',
  question_option: 'I choose my fragrance by...',
  option_ingredients: 'ingredients',
  option_ingredient: 'ingredients',
  ingredients: 'INGREDIENTS',
  option_attitude: 'attitude',
  question_family: 'I want a fragrance ...',
  question_attitude_woman: 'I want to feel ... ',
  question_attitude_man: 'I want a fragrance to...',
  question_attitude_pacollection: 'I want to feel ... ',
  question_ingredient: 'ingredients that I love the most...',
  title_attitude_elements: 'what defines you the most...',
  question_intensity: 'I want my paco fragrance intensity...',
  result_title: 'your perfect match is...',
  result_you_may_like: 'you may also like',
  restart: 'restart the quiz',
  result_cta_discover: 'discover',
  result_sample: 'get your sample',
  next: 'next step',
  please_wait: 'please wait...',
  we_are_looking: 'we are looking for your paco crush',
  welcome_title: 'are you ready to<br> find your perfect match?',
  welcome_subtitle_1: '5 simple steps to discover your Paco Rabanne fragrance',
  intensity: 'intensity',
  freshness: 'freshness',
  lasting: 'lasting',
  sweet: 'sweet',
  floral: 'floral',
  start: 'start',
  discover_smell_perfume: 'get closer to smell the perfume',
  question_restart: 'are you still here?',
  option_yes: 'Yes',
  option_no: 'no, restart the experience',
  back_to_home: 'BACK TO HOME',
  find_perfect_scent: 'FIND THE PERFECT SCENT',
  click_or_discover: 'click on a fragrance to discover the scent',
  eau_de_parfum: 'EAU DE PARFUM',
  the_original_eau_de_parfum: 'THE ORIGINAL EAU DE PARFUM',
  smell_again: 'SMELL AGAIN',
  try_and_smell: 'TRY & SMELL',
  discover_the_fragrances: 'discover the fragrances',
  choose_instructions: 'Choose 2 ingredients',
  wizard_previous: 'Previous'
}
