// import i18nStoreRequest from "./lib/i18nStoreRequest";
import cachedLists from './lib/cachedLists'
import * as api from '../services/Api'
// export default i18nStoreRequest('PROFILER', {
//   get: () => api.getProfilers(),
//   getById: (id) => api.getProfilerById(id)
// })

export default cachedLists({
  state: {
    whatever: null
  },
  mutations: {
    //whatever mutations
  },
  actions: {
    //whatever actions
  },
  getters: {
     //whatever getters
  },
  cachedLists: {
    allCategoriesPerfumes: {
      get:async (state,gender, catalogue,configId) => {
        async function getPerfumes ()
        {
          let perfumesIds = []
          const categories = state.perfumeCategory.objects[state.i18n.lang + gender]
          if(categories)
            Object.keys(categories).forEach(key => {
              const category = categories[key].result
              if (category) {
                perfumesIds = [...perfumesIds, ...category.perfumesIds.filter(id => !perfumesIds.includes(id))]
              }
            })
          else {
            const catalogues = await api.getPerfumeCategories(configId,gender)
            perfumesIds = catalogues.reduce((array,catalogue)=>array.concat(catalogue.perfumesIds.filter(id => !array.includes(id))),[])
          }
          return perfumesIds

        }
        const perfumeIds = await getPerfumes();
        return  api.getPerfumesByIds(perfumeIds, catalogue)
      },
      lang: (state) => state.i18n.lang,
      getById:(state,id,config) => api.getPerfumeById(id,config,state.i18n.lang)
    }
  },
})