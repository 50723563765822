export default {
  screen_title: 'Schritt',
  question_gender: 'Ich suche einen Duft der Kollektion',
  gender_woman: 'Damen',
  gender_man: 'Herren',
  gender_pacollection: 'Pacollection',
  question_option: 'Ich wähle meinen Duft aus nach',
  option_ingredients: 'Inhaltsstoffen',
  option_ingredient: 'Inhaltsstoffen',
  ingredients: 'INHALTSSTOFFEN',
  option_attitude: 'Stimmung',
  question_family: 'Ich möchte einen Duft',
  question_attitude_woman: 'Ich möchte mich ... fühlen',
  question_attitude_man: 'Ich möchte einen Duft für',
  question_attitude_pacollection: 'Ich möchte mich ... fühlen',
  question_ingredient: 'Die Intensität meines Duftes wähle ich hier',
  title_attitude_elements: 'Was definiert Dich am meisten?',
  question_intensity: 'Die Intensität meines Duftes wähle ich hier',
  result_title: 'Dein Perfect Match ist',
  result_you_may_like: 'Du magst vielleicht auch',
  restart: 'Quiz erneut starten',
  result_cta_discover: 'Entdecke',
  result_sample: 'Probe erhalten',
  next: 'Nächster Schritt',
  please_wait: 'Bitte warten',
  we_are_looking: 'Wir suchen nach deinem Paco Crush',
  welcome_title: 'Bist Du bereit, <br>Dein Perfect Match zu finden?',
  welcome_subtitle_1:
    '5 einfache Schritte, um Deinen Paco Rabanne Duft zu entdecken',
  intensity: 'Intensität',
  freshness: 'Frische',
  lasting: 'Haltbarkeit',
  sweet: 'süß',
  floral: 'floral',
  start: 'Start',
  discover_smell_perfume: 'Komme näher, um den Duft zu riechen',
  question_restart: '',
  option_yes: '',
  option_no: '',
  back_to_home: '',
  find_perfect_scent: '',
  click_or_discover: '',
  eau_de_parfum: '',
  the_original_eau_de_parfum: '',
  smell_again: '',
  try_and_smell: '',
  discover_the_fragrances: '',
  choose_instructions: ''
}
