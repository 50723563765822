<template>
  <pop-up-component :open="open">
    <div class="restart-container">
      <div class="title">{{ $t("question_restart") }}</div>
      <div class="actions">
        <button class="accept" @click="startTimer">{{ $t("option_yes") }}</button>
        <button
          class="btn btn--primary btn--ghost cancel"
          @click="handleRestart"
        >
          {{ $t("option_no") }}
        </button>
      </div>
    </div>
  </pop-up-component>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import getSalepointMixin from "../mixins/getSalepointMixin";
import queryStringMixin from '../mixins/queryStringMixin';
import { WIZARD_V2 } from "../router";
import PopUpComponent from "./PopUpComponent.vue";
export default {
  components: {
    PopUpComponent,
  },
  mixins:[queryStringMixin,getSalepointMixin],
  computed: {
    ...mapGetters(["isTimerFinished"]),
    open(){
      return this.$route.name===WIZARD_V2 && this.qsStep!==0 && !this.isProfiler && this.isTimerFinished
    }
  },
  methods: {
    ...mapActions(["startTimer"]),
    handleRestart() {
      this.$router.push({ name: WIZARD_V2 });
    },
  },
};
</script>

<style scoped lang="stylus">
.restart-container
  margin: auto

  .title
    text-align: center
    m-font-size(50, 60)

    .mobile &
      m-font-size(30, 40)

  .actions
    display: flex
    justify-content: space-evenly
    margin-top: vh(40px)
    .cancel
      background-color: #fff
      &:hover
        color: #000
</style>
