// import i18nStoreRequest from "./lib/i18nStoreRequest";
import cachedLists from './lib/cachedLists'
import * as api from '../services/Api'
// export default i18nStoreRequest('PROFILER', {
//   get: () => api.getProfilers(),
//   getById: (id) => api.getProfilerById(id)
// })

export default cachedLists({
  state: {
    whatever: null
  },
  mutations: {
    //whatever mutations
  },
  actions: {
    //whatever actions
  },
  getters: {
    //whatever getters
  },
  cachedLists: {
    ingredients: {
      get: (state, gender, configId,families) => api.getAllIngredients(state.i18n.lang, gender, configId, families),
      lang: (state) => state.i18n.lang
    },
  },
})