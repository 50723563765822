import fr from './lang/fr.js'
import en from './lang/en.js'
import es from './lang/es.js'
import de from './lang/de.js'
import it from './lang/it.js'

const allStrings = {
  en,
  fr,
  es,
  de,
  it
}
export const hardcodeLang = [ // TODO REMOVE WHEN ID NAME WILL BE IMPLEMENTED IN BACK
  { id: "en", name: "english" },
  { id: "de", name: "deutsch" },
  { id: "it", name: "italiano" },
  { id: "fr", name: "français" },
  { id: "pt", name: "português" },
  { id: "es", name: "español" },
  { id: "pl", name: "polski" },
  { id: "he", name: "עברית" },
  { id: "iw", name: "עברית" },      
  { id: "el", name: "ελληνικά" },
  { id: "ro", name: "română" },
]
export const langs = Object.keys(allStrings)
export function getLocalLanguage() {
  if (navigator && navigator.language) {
      let localLang = navigator.language.split('-')[0]
      if (hardcodeLang.some(e => e.id === localLang))
          return localLang
  }
  return 'en'

}
export default allStrings
