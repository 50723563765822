/**
 * 
 */
export default {
    computed: {
        concepts: {
            get() {
                const array =  !this.$route.query.concepts ? [] : (Array.isArray(this.$route.query.concepts) ? this.$route.query.concepts : [this.$route.query.concepts] ?? [])
                return array.map(id => Number(id)).filter(id => !isNaN(id));
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, concepts: value } }).catch(()=>{})
            }
        },
        ingredients:{
            get() {
                const array =  !this.$route.query.ingredients ? [] : (Array.isArray(this.$route.query.ingredients) ? this.$route.query.ingredients : [this.$route.query.ingredients] ?? [])
                return array.map(id => Number(id)).filter(id => !isNaN(id));
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, ingredients: value } }).catch(()=>{})
            }
        },
        isMan: {
            get() {
                return this.$route.query.gender === "M"
            },
            /**
             * 
             * @param {boolean} value 
             */
            set(value) {
                this.$router.push({ query: { ...this.$route.query, gender: value === true ? "M" : undefined } }).catch(()=>{})
            }

        },
        isWoman: {
            get() {
                return this.$route.query.gender === "F"
            },
            /**
             * 
             * @param {boolean} value 
             */
            set(value) {
                this.$router.push({ query: { ...this.$route.query, gender: value === true ? "F" : undefined } }).catch(()=>{})
            }

        },
        isPacollection: {
            get() {
                return !!this.$route.query.isPacollection
            },
            /**
             * 
             * @param {boolean} value 
             */
            set(value) {
                this.$router.push({ query: { ...this.$route.query, isPacollection: value === true ? true : undefined } }).catch(()=>{})
            }

        },
        intensity: {
            get() {
                return this.$route.query.intensity ?? 50;
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, intensity: value } }).catch(()=>{})
            }

        },
        gender: {
            get() {
                if(this.backoffice?.pacollection) return 'U'
                return this.$route.query.gender ?? null;
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, gender: value } }).catch(()=>{})
            }

        },
        findBy:{
            get() {
                return this.$route.query.findBy;
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, findBy: value } }).catch(()=>{})
            }
        },
        isConcept: {
            get() {
                return this.concepts.length>0;
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, concept: Boolean(value) } }).catch(()=>{})
            }
        },
        families:{
            get() {
                return !this.$route.query.families ? [] : Array.isArray(this.$route.query.families) ? this.$route.query.families.map(f => Number(f)) : [Number(this.$route.query.families)];
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, families: Array.isArray(value) ? value : [value] } }).catch(()=>{})
            }
        },
        wizard:{
            get() {
                return this.$route.query.w
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, w: value } }).catch(()=>{})
            }
        },
        qsStep:{
            get() {
                return this.$route.query.step ? Number(this.$route.query.step) : 0
            },
            set(value) {
                this.$router.push({ query: { ...this.$route.query, step: value } }).catch(()=>{})
            }
        }
        
    }
}