// import i18nStoreRequest from "./lib/i18nStoreRequest";
import cachedLists from './lib/cachedLists'
import * as api from '../services/Api'
// export default i18nStoreRequest('PROFILER', {
//   get: () => api.getProfilers(),
//   getById: (id) => api.getProfilerById(id)
// })

export default cachedLists({
  state: {
    whatever: null
  },
  mutations: {
    //whatever mutations
  },
  actions: {
    //whatever actions
  },
  getters: {
     //whatever getters
  },
  cachedLists: {
    perfumeCategory: {
      get: (state, configId, gender) => api.getPerfumeCategories(configId,gender),
      getById: (state, id, configId, gender) => api.getPerfumeCategoryById(configId,gender, id),
      //in depend of the gender, the result is diferent (like a i18n)
      lang: (state, configId, gender) => state.i18n.lang + gender,
      getLangById:(state, configId, gender) => state.i18n.lang + gender 
    },
    categoryPerfumes: {
      get: (state) => {
        function getPerfumes ()
        {
          let perfumesIds = []
          const categories = state.objects[state.i18n.lang] // TODO: it is wrongs
          if(categories)
            Object.keys(categories).forEach(key => {
              const category = categories[key].result
              if (category) {
                perfumesIds = [...perfumesIds, ...category.perfumesIds]
              }
            })
          return perfumesIds
        }      
        const perfumeIds = getPerfumes();  
        return perfumeIds.length > 0 && api.getPerfumesByIds(perfumeIds)
      },
      lang: (state) => state.i18n.lang,
    }
  },
})