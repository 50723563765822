import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import AsyncComputed from 'vue-async-computed'
import ResponsiveCss from './vue-plugins/ResponsiveCss'
import DeviceDetect from './vue-plugins/DeviceDetect'
import { getLocalLanguage } from './i18n'
import { EventTrackerPlugin } from './services/tracker'
import cachedListPlugin from './store/lib/cachedListPlugin'
import 'swiper/swiper-bundle.css'

Vue.config.productionTip = false
Vue.use(AsyncComputed)
Vue.use(VueI18n)
Vue.use(EventTrackerPlugin)

export const i18n = new VueI18n({
  locale: getLocalLanguage(), // set locale
  messages: {} // set locale messages
})

Vue.use(cachedListPlugin)

Vue.mixin(DeviceDetect)
new Vue({
  router,
  store,
  i18n,
  mixins: [ResponsiveCss],
  render: h => h(App)
}).$mount('#app')
