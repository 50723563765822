import { CTA_IDS } from "../settings/enums";

/**
 * computeds: langs, backoffice and isBackofficeUpdating
 * OBSERVATION! if you dont use it in template, then there are some reactivity "error" (not working properly)
 */
export default {
  fetch: {
    backoffice: {
      model() {
        return isNaN(this.$route.params.catalogue) ? 'profiler' : 'salepoint'
      },
      method: "getById",
      params() {
        return this.$route.params.catalogue ? [this.$route.params.catalogue] : null;
      },
    },
  },
  computed: {
    /**
     * if the view must be a "Online" version 
     * @returns {boolean}
     */
    isProfiler() {
      return isNaN(this.$route.params.catalogue) || (this.backoffice && this.backoffice.ctaId === CTA_IDS.ONLINE)
    },
    /**
     * if the frontend is showed in a machine view
     * @returns {boolean}
     */
    isMachine(){
      return !isNaN(this.$route.params.catalogue)
    },
    langs() {
      if (this.backoffice) return this.backoffice.langs
      return []
    },
    id() {
      return this.backoffice ? this.backoffice.id : null
    }
  }
}