// import i18nStoreRequest from "./lib/i18nStoreRequest";
import cachedLists from './lib/cachedLists'
import * as api from '../services/Api'
// export default i18nStoreRequest('PROFILER', {
//   get: () => api.getProfilers(),
//   getById: (id) => api.getProfilerById(id)
// })

export default cachedLists({
  state:{
    whatever: null
  },
  mutations:{
    //whatever mutations
  },
  actions:{
    //whatever actions
  },
  getters:{
    //whatever getters
  },
  cachedLists:{
    steps:{
      get: () => api.getSteps(),
      lang: ()=> 'en'
    }
  },
})