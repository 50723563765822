export default {
  next: 'next',
  try_and_smell: 'prueba y descubre',
  welcome_title: 'listo para conocer tu "perfect match" ?',
  option_ingredient: 'ingredientes',
  choose_ingredients_instructions: 'elige un máximo de 2 ingredientes',
  freshness: 'frescor',
  question_ingredient: 'mis ingredientes favoritos...',
  question_attitude_pacollection: 'quiero sentirme...',
  gender_pacollection: 'pacollection',
  option_ingredients: 'ingredientes',
  discover_the_fragrances: 'descubre las fragancias',
  gender_man: 'fragancias masculinas',
  result_sample: 'consigue una muestra',
  ingredients: 'ingredientes',
  result_you_may_like: 'quizás también te gusta',
  eau_de_parfum: 'eau de parfum',
  floral: 'floral',
  discover_smell_perfume: 'acércate para descubrir la fragancia',
  screen_title: 'etapa',
  question_attitude_woman: 'quiero sentirme...',
  question_gender: 'estoy buscando',
  find_perfect_scent: 'encuentra tu fragancia perfecta',
  the_original_eau_de_parfum: 'el eau de parfum original',
  restart: 'reiniciar el cuestionario',
  title_attitude_elements: '¿qué te define?',
  smell_again: 'sentir otra vez',
  start: 'empezar',
  back_to_home: 'volver al inicio',
  welcome_subtitle_1:
    '5 simples pasos para descubrir tu fragancia Paco Rabanne',
  question_intensity: 'quiero fragancias intensas',
  result_title: 'tu "perfect match" es',
  option_attitude: 'actitud',
  intensity: 'intensidad',
  question_option: 'escojo mi fragancia según...',
  question_family: 'quiero una fragancia...',
  result_cta_discover: 'descubrir',
  option_no: 'no, reinicia la experiencia',
  choose_options_instructions: 'elige un máximo de 2 opciones',
  click_or_discover: 'haz click sobre una fragancia para descubrir su perfume',
  option_yes: 'sí',
  question_restart: '¿sigues aquí?',
  gender_woman: 'fragancias femeninas',
  sweet: 'dulce',
  we_are_looking: 'estamos buscando tu "paco crush"',
  lasting: 'duración',
  question_attitude_man: 'con mi fragrancia, me siento...',
  please_wait: 'por favor, espera',
  step_3: 'Step 3',
  wizard_subtitle: '',
  wizard_title: '',
  wizard_previous: '',
  wizard_validate: '',
  perfume_description: '',
  perfume_flavors: ''
}
