const TIMER = 2 * 60
export default {
  state: {
    catalogue: 'ww',
    ctas: {},
    isFetching: false,
    error: null,
    timer: null,
    finished: false
  },
  mutations: {
    setFetching(state) {
      state.isFetching = true
    },
    setError(state, error) {
      state.error = error
      state.isFetching = false
    },
    setCatalogue(state, catalogue) {
      state.catalogue = catalogue
      state.isFetching = false
    },
    setCTA(state, { cta, lang }) {
      state.ctas = {
        [lang]: cta
      }
      state.isFetching = false
    },
    //TIMER
    clear(state) {
      clearTimeout(state.timer)
      state.finished = false
    },
    start(state) {
      clearTimeout(state.timer)
      state.finished = false
      state.timer = setTimeout(() => {
        state.finished = true
      }, TIMER * 1000)
    }
  },
  getters: {
    isProfiler(store) {
      return isNaN(store.catalogue)
    },
    //TIMER
    isTimerFinished: state => state.finished
  },
  actions: {
    fetchCta({ state }, lang) {
      if (state.ctas[lang]) return Promise.resolve(state.ctas[lang])

    },
    //TIMER
    clearTimer() {
      //if (!getters.isProfiler) // TODO: Remove for implement pop up
      //  commit('clear')
    },
    startTimer({commit}) {
      //if (!getters.isProfiler) // TODO: Remove for implement pop up
      commit('start')
    }
  }
}