function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * return the return of the funtion if func is a function or func if not
 * @param {(()=>T) | T} func
 * @param {import('vue').VueConstructor}
 * @return {T}
 * @template T
 */
function returnFieldOrFunctionReturn(func, instance) {
  if (func instanceof Function) return func.bind(instance)()
  return func
}
function getField(VueInstance) {
  return Object.keys(VueInstance.$options.fetch).map(field => getOneField(VueInstance, field))
}
function getOneField(VueInstance, field) {
  const fetchObj = VueInstance.$options.fetch[field]
  const method = returnFieldOrFunctionReturn(fetchObj.method, VueInstance)
  const model = returnFieldOrFunctionReturn(fetchObj.model, VueInstance)
  const params = returnFieldOrFunctionReturn(fetchObj.params, VueInstance)
  return {
    field,
    method: method === 'getById' ? 'fetchById' : 'fetch',
    getMethod: method === 'getById' ? 'getById' : 'get',
    params: params !== undefined ? () => params : method === 'getById' ? (() => [VueInstance.$route.params[model]]) : (() => []),
    model: model ?? field,
  }
}
export default {
  /**
   * 
   * @param {import('vue').VueConstructor & {$options:{fetch:{[field:string]:{
   *    model?:string,
   *    params?:()=>Array,
   *    method:'get' | 'getById'
   * }}}}} Vue 
   */
  install(Vue) {

    Vue.mixin({
      beforeCreate() {
        if (!this.$options.fetch) return
        const fields = getField(this)
        this.$options.computed = {
          ...this.$options.computed,
          ...fields.reduce((obj, key) => {
            const updatingKey = (getMethod) => getMethod === 'getById' ? 'updatingById' : 'updating'
            //console.log('KEY: ', key.field, `${key.model}/${key.getMethod}`);
            return {
              ...obj,
              [`is${capitalize(key.field)}Updating`]: function () {
                const { params, model, getMethod } = getOneField(this, key.field)
                var paramsResult = params.bind(this)()
                paramsResult = paramsResult ?? []
                return this.$store.getters[`${model}/${updatingKey(getMethod)}`](...paramsResult)
              },
              [key.field]: function () {
                const { params, model, getMethod } = getOneField(this, key.field)
                var paramsResult = params.bind(this)()
                paramsResult = paramsResult ?? []
                return this.$store.getters[`${model}/${getMethod}`](...paramsResult)
              }
            }

          }, {})
        }
      },
      mounted() {
        if (!this.$options.fetch) return
        const fields = getField(this)
        fields.forEach(field => {
          const params = field.params.bind(this)()
          if (params){
            //this[`$${field.method}${field.field}`](...params)
            this.$store.dispatch(`${field.model}/${field.method}`,params)
          }
            
        })
      },
      updated() {
        if (!this.$options.fetch) return
        const fields = getField(this)
        fields.forEach(field => {
          const params = field.params.bind(this)()
          if (params){
            //this[`$${field.method}${field.field}`](...params)
            this.$store.dispatch(`${field.model}/${field.method}`,params)
          }
            
        })
      }
    })
  },
}