export default {
screen_title: "",
question_gender: "",
gender_woman: "",
gender_man: "",
gender_pacollection: "",
question_option: "",
option_ingredients: "",
option_ingredient: "",
ingredients: "",
option_attitude: "",
question_family: "",
question_attitude_woman: "",
question_attitude_man: "",
question_attitude_pacollection: "",
question_ingredient: "",
title_attitude_elements: "",
question_intensity: "",
result_title: "",
result_you_may_like: "",
result_you_may_llike: "",
restart: "",
result_cta_discover: "",
result_sample: "",
next: "",
please_wait: "",
we_are_looking: "",
welcome_title: "",
welcome_subtitle_1: "",
intensity: "",
freshness: "",
lasting: "",
sweet: "",
floral: "",
start: "",
discover_smell_perfume: "",
question_restart: "",
option_yes: "",
option_no: "",
back_to_home: "",
find_perfect_scent: "",
click_or_discover: "",
eau_de_parfum: "",
the_original_eau_de_parfum: "",
smell_again: "",
try_and_smell: "",
discover_the_fragrances: "",
choose_instructions: ""
}